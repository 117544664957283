$(function() {

    var head = $('#top');

    var iconOpen = $('<span />').addClass('fa fa-bars');
    var iconClosed = $('<span />').addClass('fa fa-times');

    var toggler = $('<a />')
        .addClass('toggler')
        .attr({
            'role': 'button',
            'aria-controls': 'navigation',
            'aria-expanded': false,
            'href': '#navigation'
        }).html(iconOpen);


    head.attr('data-nav-visible', "false");

    var openMenu = function() {
        head.attr('data-nav-visible', "true");
        toggler.attr('aria-expanded', "true");
        toggler.html(iconClosed);
    };

    var closeMenu = function() {
        head.attr('data-nav-visible', 'false');
        toggler.attr('aria-expanded', 'false');
        toggler.html(iconOpen);
    };

    toggler.on('click', function(e) {
        e.preventDefault();
        if (toggler.attr('aria-expanded') == 'true') {
            closeMenu();
        } else {
            openMenu();
        }
    });

    head.find('.brand').after(toggler);

});
