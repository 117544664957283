$(function() {

    input = $('#planfile');

    // FF Hack :
    // http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
    input.on('focus', function(){ input.toggleClass('has-focus');});
    input.on('blur', function(){ input.toggleClass('has-focus');});

    $('#planfilesubmit').css('display', 'none');

    input.addClass('jswidget')
        .on('change', function (){
            $('#loader').submit();
        });
});
