$(function() {

    var cont = $('#lang-container');

    var link = $('#select-lang');

    link.attr({
        'role': 'button',
        'aria-controls': 'langmenu',
        'aria-expanded': false,
    });

    cont.attr('data-nav-visible', "false");

    var openMenu = function() {
        cont.attr('data-nav-visible', "true");
        link.attr('aria-expanded', "true");
    };

    var closeMenu = function() {
        cont.attr('data-nav-visible', 'false');
        link.attr('aria-expanded', 'false');
    };

    link.on('click', function(e) {
        e.preventDefault();
        if (link.attr('aria-expanded') == 'true') {
            closeMenu();
        } else {
            openMenu();
        }
    });
});
